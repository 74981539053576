<template>
  <div id="grading-scale" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(add)">
              <SdInput
                type="text"
                rules="required"
                label="Low Mark"
                v-model="gradingScale.lowMark"
                placeholder="Enter a low mark"
              />

              <SdInput
                type="text"
                rules="required"
                label="High Mark"
                v-model="gradingScale.highMark"
                placeholder="Enter a high mark"
              />

              <SdInput
                type="text"
                rules="required"
                label="Remark"
                v-model="gradingScale.remark"
                placeholder="Enter a remark"
              />

              <SdInput
                type="text"
                rules="required"
                label="Grade"
                v-model="gradingScale.grade"
                placeholder="Enter a grade"
              />

              <SdSelect
                type="text"
                label="Category"
                v-model="gradingScale.category"
              >
                <option v-for="category in categories" :key="category.name">
                  {{ category.name }}
                </option>
              </SdSelect>

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'GradingScale',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Grading Scale',
      skipQuery: true,
      gradingScale: {
        id: null,
        classGroupId: null,
        classGroup: {
          name: null,
        },
        name: '',
        promotionClass: null,
      },
      categories: [{ name: 'Lower' }, { name: 'Middle' }, { name: 'Upper' }],
    }
  },
  apollo: {
    gradingScale: {
      query: gql`
        query GradingScaleQuery($id: ID!) {
          gradingScale(id: $id) {
            id
            lowMark
            highMark
            remark
            grade
            category
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    add() {
      const lowMark = parseInt(this.gradingScale.lowMark)
      const highMark = parseInt(this.gradingScale.highMark)
      const remark = this.gradingScale.remark
      const grade = this.gradingScale.grade
      const category = this.gradingScale.category

      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createGradingScaleQuery(
                $schoolId: Int!
                $lowMark: Int!
                $highMark: Int!
                $remark: String!
                $grade: String!
                $category: String!
              ) {
                createGradingScale(
                  input: {
                    schoolId: $schoolId
                    lowMark: $lowMark
                    highMark: $highMark
                    remark: $remark
                    grade: $grade
                    category: $category
                  }
                ) {
                  gradingScale {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              lowMark: lowMark,
              highMark: highMark,
              remark: remark,
              grade: grade,
              category: category,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createGradingScale.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/grading_scales`
              )
            })
          })
          .catch((error) => {
            console.error(error.message)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateGradingScaleQuery(
                $id: Int!
                $lowMark: Int!
                $highMark: Int!
                $remark: String!
                $grade: String!
                $category: String!
              ) {
                updateGradingScale(
                  input: {
                    id: $id
                    lowMark: $lowMark
                    highMark: $highMark
                    remark: $remark
                    grade: $grade
                    category: $category
                  }
                ) {
                  gradingScale {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.gradingScale.id),
              lowMark: lowMark,
              highMark: highMark,
              remark: remark,
              grade: grade,
              category: category,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateGradingScale.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/grading_scales`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Grading Scales',
        route: `/school/${this.schoolId}/grading_scales`,
      },
    ])
  },
}
</script>
